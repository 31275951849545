<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <section class="text-center">
    <p>{{info}}</p>

    <section class="my-2">
      <b-form-checkbox
        id="checkbox-1"
        v-model="accepted"
        name="checkbox-1"
        value="accepted"
        unchecked-value="not_accepted"
      >
        <span style="text-align: left">
          I understand that editing the fields listed above will make the group insecure and I wish to proceed with the update
        </span>

      </b-form-checkbox>
    </section>
    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer"></div>
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button :disabled="accepted === 'not_accepted'" variant="danger" @click="$emit('ok', true)">Update</b-button>
    </section>
  </section>
</template>
<!--eslint-enable-->

<script>

export default {
  components: {},
  props: {
    info: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      accepted: 'not_accepted',
    };
  },
};
</script>
