<template>
  <feather-icon v-if="insecureFields.includes(fieldName) && showIcon" v-b-tooltip="warningText" class="text-warning" icon="AlertTriangleIcon" />
</template>
<script>
export default {
  props: {
    insecureFields: {
      type: Array,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
    field: {
      type: [String, Boolean],
      required: true,
    }
  },
  data() {
    return {
      originalValue: this.field,
      showIcon: true,
      warningText: "The current setting of this field is not secure",
    };
  },
  watch: {
    field(newValue) {
      this.showIcon = newValue === this.originalValue
    }
  }
}
</script>
